import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import { BudgetPage } from "./pages/budget";
import { LoginPage } from "./pages/login";
import { AuthContextProvider, ProtectedRoute } from "./contexts/Auth.context";

function App() {
	return (
		<Router>
			<AuthContextProvider>
				<div className="container-fluid">
					<div className="row">
						<div className="col">
							<Routes>
								<Route path="/" element={<ProtectedRoute><BudgetPage /></ProtectedRoute>} />
								<Route path="/auth/login" element={<LoginPage />} />
							</Routes>
						</div>
					</div>
				</div>
			</AuthContextProvider>
		</Router>
	);
}

export default App;

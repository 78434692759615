import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/Auth.context';

export type StrapiEndpoint = string | (() => string);
export type StrapiRefreshFn = () => void;

export const useStrapiDataset = <T>(endpoint: StrapiEndpoint): [T[], StrapiRefreshFn] => {
	const auth = useAuth();
	const [update, setUpdate] = useState(false);
	const [data, setData] = useState<T[]>([]);

	const refresh = () => {
		setUpdate(!update);
	}

	useEffect(() => {
		const _endpoint = typeof endpoint === 'string'
			? endpoint
			: endpoint();
		axios.get(`${process.env.REACT_APP_API_HOST}${_endpoint}`, {
			headers: {
				Authorization: `bearer ${auth.state.userToken}`
			}
		}).then(res => setData(res.data.data.map((row: any) => Object.assign(row.attributes, {id: row.id}))));
	}, [update]);

	return [data, refresh];
}
import { v1 } from 'uuid';
import React, { useContext, useMemo, useState } from 'react';

export type NotificationLevel = 'primary' | 'success' | 'warning' | 'danger';

export interface Notification {
	id: string,
	message: string,
	level: NotificationLevel
}

export type NotificationFn = (message: string, level: NotificationLevel) => void;

export interface NotificationState {
	notifications: Notification[]
	alert: NotificationFn
}

export const NotificationContext = React.createContext<NotificationState>({
	notifications: [],
	alert: (message: string, level: string) => {}
});

export const useNotification = () => {
	return useContext(NotificationContext);
}

export function NotificationToast(props: {notification: Notification}) {
	return <div className={`toast show align-items-center border-0 text-bg-${props.notification.level}`} role="alert" aria-live="assertive" aria-atomic="true" style={{width: '100%'}}>
		<div className="d-flex">
			<div className="toast-body">
				{props.notification.message}
			</div>
			<button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
		</div>
	</div>
}

export function NotificationContextProvider(props: any) {
	const [notifications, setNotifications] = useState<Notification[]>([]);

	const alert: NotificationFn = (message: string, level: NotificationLevel) => {
		const id = v1();
		setNotifications([...notifications, {
			id,
			message,
			level
		}]);

		setTimeout(() => {
			setNotifications(notifications
				.filter(notification => notification.id !== id)
			);
		}, 3 * 1000);
	};

	const state = useMemo(() => Object.assign({
		notifications,
		alert
	}), [notifications]);

	return (
		<NotificationContext.Provider value={state}>
			<div className="toast-container position-fixed" style={{top: 0, left: 0, right: 0, width: '100%', padding: '.5em'}}>
				{notifications.map((notification) => <NotificationToast key={notification.id} notification={notification}></NotificationToast>)}
			</div>

			{props.children}
		</NotificationContext.Provider>
	);
}
import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from '../lib/LocalStorage';
import jwt_decode from "jwt-decode";

const initialState = {
	isLoggedIn: false,
	isLoginPending: false,
	loginError: null,
	userToken: null
};

export const AuthContext = React.createContext({
	state: initialState,
	login: async (identifier: string, password: string) => false,
	logout: async () => false
});

export const useAuth = () => {
	return useContext(AuthContext);
};

export function ProtectedRoute(props: any) {
	const { state } = useAuth();
	if (!state.isLoggedIn) {
		return <Navigate to="/auth/login" />;
	}
	return props.children;
}

export function AuthContextProvider(props: any) {
	const [state, setState] = useLocalStorage('user-state', initialState);

	// bind to logout when expires
	useEffect(() => {
		if (state.userToken) {
			const data: any = jwt_decode(state.userToken);
			const timeToExp = data.exp - Math.floor(Date.now() / 1000);

			return () => setTimeout(() => {
				logout();
			}, timeToExp);
		}

		return () => { };
	}, [state.userToken]);

	// function to handle login
	const login = async (email: string, password: string) => {
		// set to pending
		setState({
			isLoggedIn: false,
			isLoginPending: true,
			loginError: null,
			userToken: null
		});

		try {
			// try login
			const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/auth/local`, {
				identifier: email,
				password: password
			});

			// set login
			setState({
				isLoggedIn: true,
				isLoginPending: false,
				loginError: null,
				userToken: res.data.jwt
			});

			return true;

		} catch (e: any) {
			// set invalid login
			setState({
				isLoggedIn: false,
				isLoginPending: false,
				loginError: e.message,
				userToken: null
			});

			return false;
		}
	}

	// function to handle logout
	const logout = async () => {
		// set logout
		setState({
			isLoggedIn: false,
			isLoginPending: false,
			loginError: null,
			userToken: null
		});

		return true;
	}

	// check if token is expired
	if (state.userToken) {
		const data: any = jwt_decode(state.userToken);
		const timeToExp = data.exp - Math.floor(Date.now() / 1000);

		if (timeToExp <= 0) {
			logout();
		}
	}

	return (
		<AuthContext.Provider
			value={{
				state: state as typeof initialState,
				login,
				logout,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
}
import React, { useEffect, useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
	// load initial value
	const initialStoredValue = localStorage.getItem(key);

	// use loaded value for initial
	const [value, setValue] = useState<T>(initialStoredValue
		? JSON.parse(initialStoredValue) as T
		: initialValue);

	// write updates
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value));
	}, [value]);

	return [value, setValue];
}
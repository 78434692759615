import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';
import { useAuth } from "../../contexts/Auth.context";
import { useStrapiDataset } from "../../lib/Strapi";
import { useNotification } from "../../contexts/Notification.context";
import { useToggle } from "../../lib/Misc";
import { useLocalStorage } from "../../lib/LocalStorage";

function formatCurrency(num: Number) {
	return num.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
}

export function BudgetHeaderItem(props: any) {
	const amount = Number(props.amount);

	const onTrack = amount > 0;

	const classes = 'col-12 col-sm-6 text-center ' + (onTrack ? 'text-success' : 'text-danger');

	return (
		<div className={classes}>
			<div>
				{props.header}
			</div>
			<div className="display-1">
				{formatCurrency(amount)}
			</div>
		</div>
	)
}

export function BudgetInputForm(props: any) {
	const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
	const [memo, setMemo] = useState('');
	const [amount, setAmount] = useState('');
	const [category, setCategory] = useState<any>(null);
	const [budget, setBudget] = useState<any>(null);
	const auth = useAuth();
	const notifications = useNotification();

	const [budgets] = useStrapiDataset<any>('/api/ledger-budgets?sort[0]=name');
	useEffect(() => {
		if (budget === null && budgets.length > 0) {
			setBudget(budgets[0].id);
		}
	}, [budgets]);

	const [categories] = useStrapiDataset<any>('/api/ledger-categories?sort[0]=name');
	useEffect(() => {
		if (category === null && categories.length > 0) {
			setCategory(categories[0].id);
		}
	}, [categories]);

	async function onSubmit(e: any) {
		e.preventDefault();

		try {
			await axios.post(`${process.env.REACT_APP_API_HOST}/api/ledger-items`, {
				data: {
					date: date,
					memo: memo,
					amount: amount,
					ledger_category: category,
					ledger_budgets: [budget]
				}
			}, {
				headers: {
					Authorization: `bearer ${auth.state.userToken}`
				}
			});

			notifications.alert('record added', 'success');
		} catch (err) {

			notifications.alert('record could not be added', 'danger');
		}

		setAmount('');
		setMemo('');
		props.refresh();
	}

	return (
		<form onSubmit={e => onSubmit(e)}>
			<div className="form-floating mb-3">
				<input
					type="date"
					className="form-control text-left"
					value={date}
					onChange={e => setDate(e.target.value)}
				/>
				<label>Date</label>
			</div>
			<div className="form-floating mb-3">
				<input
					type="text"
					className="form-control text-left"
					value={memo}
					onChange={e => setMemo(e.target.value)}
				/>
				<label>Memo</label>
			</div>
			<div className="form-floating mb-3">
				<input
					type="number"
					className="form-control"
					min={0}
					step={0.01}
					value={amount}
					onChange={e => setAmount(e.target.value)}
				/>
				<label>Amount</label>
			</div>
			<div className="form-floating mb-3">
				<select
					className="form-select"
					style={{paddingLeft: '12px'}}
					onChange={e => setCategory(e.target.value)}
				>
					{categories.map((category) => <option key={category.id} value={category.id}>{category.name}</option>)}
				</select>
				<label>Category</label>
			</div>
			<div className="form-floating mb-3">
				<select
					className="form-select"
					style={{paddingLeft: '12px'}}
					onChange={e => setBudget(e.target.value)}
				>
					{budgets.map((budget) => <option key={budget.id} value={budget.id}>{budget.name}</option>)}
				</select>
				<label>Budget</label>
			</div>
			<button type="submit" className="btn btn-sm btn-secondary w-100">Add</button>
		</form>
	)
}

export function BudgetLineItem(props: any) {
	const auth = useAuth();

	const onClick = async (e: any) => {
		await axios.delete(`${process.env.REACT_APP_API_HOST}/api/ledger-items/${props.item.id}`, {
			headers: {
				Authorization: `bearer ${auth.state.userToken}`
			}
		});

		props.refresh();
	}

	return (
		<div className="row border-bottom pb-1 pt-1">
			<div className="col">
				<strong className="d-block d-sm-none">date<br /></strong>
				{moment(props.item.date).format("MMM DD, YYYY")}
			</div>
			<div className="col text-center">
				<strong className="d-block d-sm-none">memo<br /></strong>
				{props.item.memo}
			</div>
			<div className="col text-end">
				<strong className="d-block d-sm-none">amount<br /></strong>
				{formatCurrency(Number(props.item.amount))}
			</div>
			<div className="col text-end" style={{ "maxWidth": "2em", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
				<span
					className="material-symbols-outlined text-danger"
					onClick={e => onClick(e)}
				>remove</span>
			</div>
		</div>
	);
}

export function BudgetHeader(props: any) {
	const summary = props.budgetSummary[0];
	const remaining = summary.total - summary.used;
	const days = moment(summary.days[summary.days.length - 1].date).diff(summary.days[0].date, 'days');

	return (
		<div className="row">
			<BudgetHeaderItem header={`budget (${days} days)`} amount={remaining} />
			{/* <BudgetHeaderItem header="today" amount={0} /> */}
		</div>
	);
}

export function BudgetGraph(props: any) {
	const lineData = {
		labels: Object.values(props.budgetSummary[0].days).map((x: any) => x.date),
		datasets: props.budgetSummary.map((summary: any) => {
			const data = Object.values(summary.days).map((x: any) => x.amount);
			return {
				label: `${summary.period.startDate}`,
				data,
				borderColor: 'rgba(0, 0, 0, 0.3)',
				backgroundColor: 'transparent',
				pointRadius: data.map(pt => pt === 0 ? 0 : 3)
			}
		})
	};

	const barData = {
		labels: props.budgetSummary.map((summary: any) => summary.period.startDate),
		datasets: [
			{
				label: 'total',
				data: props.budgetSummary.map((summary: any) => summary.total),
				stack: 'test',
				backgroundColor: 'rgba(0, 0, 0, 0.05)',
				borderColor: 'rgba(0, 0, 0, 0.1)',
			},
			{
				label: 'used',
				data: props.budgetSummary.map((summary: any) => summary.used),
				stack: 'test',
				backgroundColor: props.budgetSummary.map((summary: any) => summary.used > summary.total
					? 'rgba(255, 53, 69, 0.4)'
					: 'rgba(25, 135, 84, 0.4)'
				),
				borderColor: props.budgetSummary.map((summary: any) => summary.used > summary.total
					? 'rgb(255, 53, 69)'
					: 'rgb(25, 135, 84)'
				),
			},
		]
	}

	return <>
		<div style={{ maxHeight: '35%' }}>
			<Line
				options={{
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxis: {
							display: false
						},
						yAxis: {
							ticks: {
								stepSize: 100
							}
						}
					}
				}}
				data={lineData}
			/>
		</div>
		<div style={{ maxHeight: '35%' }}>
			<Bar
				options={{
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxis: {
							display: true,
							stacked: true
						},
						yAxis: {
							stacked: false,
							beginAtZero: true,
							ticks: {
								stepSize: 100
							}
						}
					}
				}}
				data={barData}
			/>
		</div>
	</>;
}

export function BudgetSection(props: any) {
	const [budget, setBudget] = useLocalStorage<any>('selectedBudget', 0);
	const [budgets] = useStrapiDataset<any>(`/api/ledger-budgets?sort[0]=name`);
	const [budgetData, refreshBudgetData] = useStrapiDataset<any>(() => `/api/ledger-items?sort[0]=date:desc&sort[1]=createdAt:desc&pagination[pageSize]=1000&filters[ledger_budgets][id][$eq]=${budget}`);
	const [budgetPeriod, refreshBudgetPeriod] = useStrapiDataset<any>(() => `/api/ledger-budget-periods?sort[0]=startDate:desc&filters[ledger_budget][id][$eq]=${budget}`);

	useEffect(() => {
		refreshBudgetData();
	}, [props.refreshToken]);

	useEffect(() => {
		refreshBudgetData();
		refreshBudgetPeriod();
	}, [budget]);
	
	const [budgetSummary] = useMemo(() => {
		// for each period
		return [budgetPeriod.map((period) => {
			const summary: any = {
				period,
				total: period.amount,
				used: 0,
				days: []
			};

			// walk every day summing up values each day
			const endDate = period.endDate ? moment(period.endDate) : moment();
			const diff = endDate.diff(moment(period.startDate), 'days');
			for (let i=0 ; i<=diff ; i++) {
				const date = moment(period.startDate).add(i, 'day').format('YYYY-MM-DD');
				const dateSummary = {
					date,
					amount: budgetData.filter(data => data.date === date)
						.reduce((a, b) => a + b.amount, 0)
				};
				summary.days.push(dateSummary);
				summary.used += dateSummary.amount;
			}

			return summary;
		})];
	}, [budgetData, budgetPeriod]);

	return <>
		<div className="row">
			<div className="col text-center">
				<strong>- Budget -</strong>
			</div>
		</div>
		<form>
			<div className="form-floating">
				<select
					className="form-select"
					style={{paddingLeft: '12px'}}
					value={budget}
					onChange={e => setBudget(e.target.value)}
				>
					<option key={0} value={0}>{'None'}</option>
					{budgets.map((budget) => <option key={budget.id} value={budget.id}>{budget.name}</option>)}
				</select>
				<label>Selected Budget</label>
			</div>
		</form>
		{budgetSummary.length > 0 &&
			<>
				<BudgetHeader budget={budget} budgetSummary={budgetSummary} />
				<BudgetGraph budget={budget} budgetSummary={budgetSummary} />
			</>
		}
	</>;
}

export function InputSection(props: any) {
	return <>
		<div className="row mt-5">
			<div className="col text-center">
				<strong>- INPUT -</strong>
			</div>
		</div>

		<div className="row">
			<div className="col">
				<BudgetInputForm refresh={props.refresh} />
			</div>
		</div>
	</>
}

export function HistorySection(props: any) {
	const [budgetItems, refreshBudgetItems] = useStrapiDataset<any>(() => `/api/ledger-items?sort[0]=date:desc&sort[1]=createdAt:desc&pagination[pageSize]=1000&filters[date][$gte]=${moment().subtract(30, "day").format('YYYY-MM-DD')}`);

	useEffect(() => {
		refreshBudgetItems()
	}, [props.refreshToken]);

	return <div className="row mt-5">
		<div className="col">
			<div className="container-fluid">
				<div className="row">
					<div className="col text-center">
						<strong>- HISTORY (30 days) -</strong>
					</div>
				</div>
				{budgetItems.map((item: any) => <BudgetLineItem key={item.id} item={item} refresh={props.refresh} />)}
			</div>
		</div>
	</div>;
}

export function BudgetPage() {
	const [refreshToken, refresh] = useToggle();

	return (
		<div className="container-fluid">
			<BudgetSection refreshToken={refreshToken}></BudgetSection>

			<InputSection refresh={refresh}></InputSection>

			<HistorySection refreshToken={refreshToken} refresh={refresh}></HistorySection>
		</div>
	);
}

import { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../contexts/Auth.context";

export function LoginForm() {
	const { login, state } = useContext(AuthContext);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	async function onSubmit(e: any) {
		e.preventDefault();

		if (await login(email, password)) {
			navigate('/');
		}
	}

	return (
		<form onSubmit={(e) => onSubmit(e)}>
			<h1 className="text-center">Login</h1>
			{state.loginError && <div className="alert alert-danger text-center" role="alert">
				invalid username password pair
			</div>}
			<div className="form-floating mb-3">
				<input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
				<label>email</label>
			</div>
			<div className="form-floating mb-3">
				<input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
				<label>password</label>
			</div>
			<button type="submit" className="btn btn-sm btn-primary w-100">
				Sign In
			</button>
		</form>
	);
}

export function LoginPage() {
	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				position: "absolute",
				display: "flex",
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				style={{
					width: "80%",
				}}
			>
				<LoginForm />
			</div>
		</div>
	);
}
